<template>
  <el-container>
    <el-aside>
      <SiderMenu />
    </el-aside>
    <el-container class="mars-ape-layout">
      <el-header class="mars-ape-layout-header">
        <Header />
      </el-header>
      <el-main>
        <div v-if="isRootRoutePath">
          <PageHeaderLayout>
            <div class="main-page-content">
                <el-row :gutter="40" class="panel-group">
                  <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
                    <div class="card-panel" @click="handleSetLineChartData('newVisitis')">
                      <div class="card-panel-icon-wrapper icon-people">
                        <i class="iconfont el-icon-user" style="font-size:38px"></i>
                      </div>
                      <div class="card-panel-description">
                        <div class="card-panel-text">
                          当日总客流
                        </div>
                        <count-to :start-val="0" :end-val="user_number" :duration="2600" class="card-panel-num" />
                      </div>
                    </div>
                  </el-col>
                  <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
                    <div class="card-panel" @click="handleSetLineChartData('messages')">
                      <div class="card-panel-icon-wrapper icon-message">
                        <i class="iconfont el-icon-user" style="font-size:38px"></i>
                      </div>
                      <div class="card-panel-description">
                        <div class="card-panel-text">
                          男性
                        </div>
                        <count-to :start-val="0" :end-val="book_number" :duration="3000" class="card-panel-num" />
                      </div>
                    </div>
                  </el-col>
                  <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
                    <div class="card-panel" @click="handleSetLineChartData('purchases')">
                      <div class="card-panel-icon-wrapper icon-money">
                        <i class="iconfont el-icon-user" style="font-size:38px"></i>
                      </div>
                      <div class="card-panel-description">
                        <div class="card-panel-text">
                          女性
                        </div>
                        <count-to :start-val="0" :end-val="sale_total" :duration="3200" class="card-panel-num" />
                      </div>
                    </div>
                  </el-col>
                  <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
                    <div class="card-panel" @click="handleSetLineChartData('shoppings')">
                      <div class="card-panel-icon-wrapper icon-shopping">
                        <i class="iconfont el-icon-money" style="font-size:38px"></i>
                      </div>
                      <div class="card-panel-description">
                        <div class="card-panel-text">
                          男女比率
                        </div>
                        <count-to :start-val="0" :end-val="sale_numer" :duration="3600" class="card-panel-num" />
                      </div>
                    </div>
                  </el-col>
                </el-row>
               <el-row :gutter="32">
                <el-col :xs="24" :sm="24" :lg="12">
                <p>24小时客流量</p>
                <day-chart :chart-data="dayChartData" />
                </el-col>
                <el-col :xs="24" :sm="24" :lg="12">
                  <p>一周内访客类型</p>
                  <bar-chart />
              </el-col>
              </el-row>
              <el-row style="background:#fff;padding:16px 16px 0;margin-bottom:32px;">
                <h3>一个月访问情况</h3>
                <month-chart :chart-data="monthChartData" />
              </el-row>
             
              
            </div>
          </PageHeaderLayout>
        </div>

        <router-view />
      </el-main>
      <el-footer>
        <Footer />
      </el-footer>
    </el-container>
  </el-container>
</template>
<script>
import SiderMenu from "./SiderMenu";
import Header from "./Header";
import Footer from "./Footer";
import CountTo from 'vue-count-to'
import PageHeaderLayout from "@/layouts/PageHeaderLayout";
import TreeTable from "@/components/TreeTable";
import ModalDialog from "@/components/ModalDialog";
import { mapGetters } from "vuex";
import ApeUploader from "@/components/ApeUploader";
import PanelGroup from '@/components/admin/components/PanelGroup'
import MonthChart from '@/components/admin/components/MonthChart'
import DayChart from '@/components/admin/components/DayChart'
import BarChart from '@/components/admin/components/BarChart'
const lineChartData = {
  monthDatas: {
    expectedData: [396, 396, 396, 396, 396, 396, 400,396, 396, 399, 396, 396, 396, 396, 396, 396, 400,396, 396, 399,396, 396, 399, 396, 396, 396, 396, 396, 396, 400,500],
    actualData: [12900, 12007, 2291, 1154, 1162, 2140, 2145, 2291, 1154, 1162, 2140, 2145, 12291, 1154, 1162, 2140, 2145, 2291, 1154, 1162, 2140, 2145, 2291, 1154, 1162,12145, 2291, 21154, 1162, 1154, 1162]
  },
  newVisitis: {
    expectedData: [100, 120, 161, 134, 105, 160, 165],
    actualData: [120, 82, 91, 154, 162, 140, 145]
  },
  dayDatas: {
    expectedData: [1200, 12007, 2291, 1154, 1162, 2140, 2145, 2291, 1154, 1162, 2140, 2145, 12291, 1154, 1162, 2140, 2145, 2291, 1154, 1162, 2140, 2145, 2291],
    actualData: [100, 1207, 291, 114, 112, 140, 145, 2291, 1154, 1162, 2140, 2145, 12291, 1154, 1162, 2140, 2145, 2291, 1154, 1162, 2140, 2145, 2291]
  },
  messages: {
    expectedData: [200, 192, 120, 144, 160, 130, 140],
    actualData: [180, 160, 151, 106, 145, 150, 130]
  },
  purchases: {
    expectedData: [80, 100, 121, 104, 105, 90, 100],
    actualData: [120, 90, 100, 138, 142, 130, 130]
  },
  shoppings: {
    expectedData: [130, 140, 141, 142, 145, 150, 160],
    actualData: [120, 82, 91, 154, 162, 140, 130]
  }
}
export default {
  components: {
    CountTo,
    SiderMenu,
    Header,
    Footer,
    PageHeaderLayout,
    TreeTable,
    ModalDialog,
    ApeUploader,
    MonthChart,
    DayChart,
    PanelGroup,
    BarChart
  },
  data() {
    return {
      loadingStaus: true,
      selectedList: [],
      coverimage: "",
      depth: 0,
      uploadHeaders: {},
      dialogVisible: false,
      dialogData: {
        visible: false,
        title: "",
        width: "24%",
        loading: true,
        modal: false,
      },
      sale_numer:0,
      book_number:0,
      sale_total:0,
      user_number:0,
      monthChartData: lineChartData.monthDatas,
      dayChartData: lineChartData.dayDatas,
      // 表格列表数据
      scheduleList: [],
      defaultFormData: {
        display_name: "",
        parent_id: 0,
      },
      // 表单结构
      formData: {},
      // 表单验证
      rules: {
        display_name: [
          { required: true, message: "输入任务名称", trigger: "blur" },
        ],
        done_time: [
          { required: true, message: "输入任务标识", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["userPermissions", "buttonType"]),
    isRootRoutePath: function () {
      return this.$route.path == "/";
    },
  },
  methods: {
    handleSetLineChartData(type) {
      this.dayChartData = lineChartData[type]
    }
  },
  async mounted() {
    if (!this.isRootRoutePath) {
      return;
    }
  
  },
};
</script>
<style lang="scss" scoped>
.panel-group {
  margin-top: 18px;

  .card-panel-col {
    margin-bottom: 32px;
  }

  .card-panel {
    height: 108px;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    overflow: hidden;
    color: #666;
    background: #fff;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, .05);
    border-color: rgba(0, 0, 0, .05);

    &:hover {
      .card-panel-icon-wrapper {
        color: #fff;
      }

      .icon-people {
        background: #40c9c6;
      }

      .icon-message {
        background: #36a3f7;
      }

      .icon-money {
        background: #f4516c;
      }

      .icon-shopping {
        background: #34bfa3
      }
    }

    .icon-people {
      color: #40c9c6;
    }

    .icon-message {
      color: #36a3f7;
    }

    .icon-money {
      color: #f4516c;
    }

    .icon-shopping {
      color: #34bfa3
    }

    .card-panel-icon-wrapper {
      float: left;
      margin: 14px 0 0 14px;
      padding: 16px;
      transition: all 0.38s ease-out;
      border-radius: 6px;
    }

    .card-panel-icon {
      float: left;
      font-size: 48px;
    }

    .card-panel-description {
      float: right;
      font-weight: bold;
      margin: 26px;
      margin-left: 0px;

      .card-panel-text {
        line-height: 18px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 16px;
        margin-bottom: 12px;
      }

      .card-panel-num {
        font-size: 20px;
      }
    }
  }
}

@media (max-width:550px) {
  .card-panel-description {
    display: none;
  }

  .card-panel-icon-wrapper {
    float: none !important;
    width: 100%;
    height: 100%;
    margin: 0 !important;

    .svg-icon {
      display: block;
      margin: 14px auto !important;
      float: none !important;
    }
  }
}
</style>

<style lang="stylus">
.mod-index {
  border-radius: 5px;
  background: #fff;
  padding: 24px;
  min-height: 800px;
  line-height: 1.5;

  p {
    margin: 0;
  }

  h3 {
    margin-block-start: 0;
    margin-block-end: 0.5em;
  }

  .xm-gt img {
    height: 240px;
    margin-left: -16px;
  }

  .jx-zz img {
    height: 400px;
    margin-top: 24px;
    margin-right: 24px;
  }
}

.el-container {
  background: #f0f2f5;
}

.el-aside {
  min-height: 100vh;
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  position: relative;
  width: auto !important;
  z-index: 10;
  transitionall: 0.3s;
  background: #001529;
}

.el-header {
  height: 64px !important;
  padding: 0px;
  width: 100%;
}

.el-main {
  padding: 0px;
  margin: 24px;
  overflow: inherit;
  flexnone: @css { !important };
  min-height: 720px;
}

.main-page-content {
  border-radius: 5px;
  background: #ffffff;
  padding: 24px;
}

.el-footer {
  height: auto !important;
}

</style>