<template>
  <div class="mars-ape-layout-footer">
    <div class="mars-ape-layout-footer-link">
    </div>
    <div class="mars-ape-layout-footer-copyright">
      Copyright <i class="iconfont icon-ic_copyright"></i>  {{year}} - {{year+1}} cheetah科技 | 备案号：<a href="http://www.beian.miit.gov.cn/">省ICP备XXXXXXXX号</a>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    }
  },
}  
</script>    
<style lang="stylus">
.mars-ape-layout-footer
  font-size 14px
  padding 0 16px
  margin 48px 0 24px
  text-align center
  color rgba(255, 255, 255, 255)
.mars-ape-layout-footer-link
  margin-bottom 8px
.mars-ape-layout-footer-copyright
  a
    color #1890ff
    text-decoration none
</style>